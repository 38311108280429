import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Footer from "../../components/Layout/Footer";

export default function NewsletterPage({ data }) {
  const { title, newsletterFile } = data.sanityNewsletter;

  useEffect(() => {
    window.location.replace(newsletterFile.asset.url);
  }, [newsletterFile.asset.url]);

  return (
    <>
      <iframe
        title={title}
        src={newsletterFile.asset.url}
        style={{ width: "100%", height: "100%", border: 0 }}
      />
      <Footer />
    </>
  );
}

export const query = graphql`
  query NewsletterPageQuery($slug__current: String) {
    sanityNewsletter(slug: { current: { eq: $slug__current } }) {
      title
      newsletterFile {
        asset {
          url
        }
      }
    }
  }
`;
